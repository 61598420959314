import 'vanilla-cookieconsent/dist/cookieconsent.css'
import '@orestbida/iframemanager/dist/iframemanager.css'
import * as CookieConsent from 'vanilla-cookieconsent'
import '@orestbida/iframemanager/dist/iframemanager.js'

const im = iframemanager()

im.run({
  onChange: ({ changedServices, eventSource }) => {
    console.log(changedServices)
    console.log(eventSource)
    if (eventSource.type === 'click') {
      const servicesToAccept = [
        ...CookieConsent.getUserPreferences().acceptedServices['embed'],
        ...changedServices,
      ]

      CookieConsent.acceptService(servicesToAccept, 'embed')
    }
  },

  currLang: 'de',

  services: {
    curator: {
      onAccept: async (div, setIframe) => {
        // Lade das Curator.io Skript nur bei Zustimmung
        div.style.backgroundColor = '#fff9ef'
        const dataId = div.getAttribute('data-id')
        var script = document.createElement('script')
        script.async = true
        script.charset = 'UTF-8'
        script.src = dataId
        document.body.appendChild(script)
        var feedDiv = document.createElement('div')
        feedDiv.id = 'curator-feed-test-feed-layout'
        feedDiv.innerHTML = '<a href="https://curator.io" target="_blank" class="crt-logo crt-tag">Powered by Curator.io</a>'
        div.appendChild(feedDiv)
      },

      onReject: (iframe, div, showNotice) => {
        console.log('onReject')
        var feedDiv = document.getElementById('curator-feed-test-feed-layout')
        if (feedDiv) {
          feedDiv.innerHTML = ''
        }

        // Script mit ID 'curator-script' entfernen
        var script = document.getElementById('curator-script')
        if (script) {
          script.remove()
        }

        // Optional: Hintergrundfarbe ändern oder zurücksetzen
        div.style.backgroundColor = ''
        showNotice()
      },
      languages: {
        de: {
          notice: 'Dieser Inhalt wird von einem externen Anbieter gehostet. Durch die Anzeige des externen Inhalts akzeptieren Sie die <a rel="noreferrer" href="https://curator.io/privacy-policy" title="Bedingungen und Konditionen" target="_blank">Bedingungen und Konditionen</a> von curator.io.',
          loadBtn: 'Laden',
          loadAllBtn: 'Nicht nochmal fragen',
        }
      },
    },

    youtube: {
      embedUrl: 'https://www.youtube-nocookie.com/embed/{data-id}',
      thumbnailUrl: 'https://i3.ytimg.com/vi/{data-id}/hqdefault.jpg',

      iframe: {
        allow:
          'accelerometer; encrypted-media; gyroscope; picture-in-picture; fullscreen;',
      },

      languages: {
        de: {
          notice: 'Dieser Inhalt wird von einem externen Anbieter gehostet. Durch die Anzeige des externen Inhalts akzeptieren Sie die <a rel="noreferrer" href="https://www.youtube.com/t/terms" title="Bedingungen und Konditionen" target="_blank">Bedingungen und Konditionen</a> von youtube.com.',
          loadBtn: 'Video laden',
          loadAllBtn: 'Nicht nochmal fragen',
        }
      },
    },

    vimeo: {
      embedUrl: 'https://player.vimeo.com/video/{data-id}',
      iframe: {
        allow: 'fullscreen; picture-in-picture;',
      },

      thumbnailUrl: async (dataId, setThumbnail) => {
        const url = `https://vimeo.com/api/v2/video/${dataId}.json`
        const response = await (await fetch(url)).json()
        const thumbnailUrl = response[0]?.thumbnail_large
        thumbnailUrl && setThumbnail(thumbnailUrl)
      },

      languages: {
        de: {
          notice:
            'Dieser Inhalt wird von einem externen Anbieter gehostet. Durch die Anzeige des externen Inhalts akzeptieren Sie die <a rel="noreferrer noopener" href="https://vimeo.com/terms" target="_blank">Bedingungen und Konditionen</a> von vimeo.com.',
          loadBtn: 'Video laden',
          loadAllBtn: 'Nicht nochmal fragen',
        },
      },
    },
  },
})

CookieConsent.run({
  guiOptions: {
    consentModal: {
      layout: 'box wide',
      position: 'bottom center',
      equalWeightButtons: true,
      flipButtons: false,
    },
    preferencesModal: {
      layout: 'box',
      equalWeightButtons: true,
      flipButtons: false,
    },
  },

  categories: {
    necessary: {
      readOnly: true,
      enabled: true,
    },
    embed: {
      services: {
        curator: {
          label: 'Curator Embed',
          onAccept: () => im.acceptService('curator'),
          onReject: () => im.rejectService('curator'),
        },
        youtube: {
          label: 'Youtube Embed',
          onAccept: () => im.acceptService('youtube'),
          onReject: () => im.rejectService('youtube'),
        },
        vimeo: {
          label: 'Vimeo Embed',
          onAccept: () => im.acceptService('vimeo'),
          onReject: () => im.rejectService('vimeo'),
        },
      },
    },

    ads: {},
  },

  language: {
    default: 'de',
    translations: {
      de: {
        'consentModal': {
          'label': 'Cookie-Einwilligung',
          'title': 'Wir verwenden Cookies.',
          'description': 'Damit können wir Dir audiovisuelle Formate und einige Funktionen für ein ansprechendes Webangebot ermöglichen. Einige der Cookies sind notwendig für die Funktion der Website, andere helfen uns, die Website interaktiver und ansprechender zu gestalten. Um unseren eigenen Ansprüchen beim Datenschutz gerecht zu werden, erfassen wir lediglich anonymisierte Nutzer*innendaten mit Matomo.',
          'acceptAllBtn': 'Alle akzeptieren',
          'closeIconLabel': 'Alle ablehnen und schließen',
          'acceptNecessaryBtn': 'Alle ablehnen',
          'showPreferencesBtn': 'Einstellungen verwalten',
          'footer': '<a href="/datenschutz">Datenschutzerklärung</a>'
        },
        'preferencesModal': {
          'title': 'Cookie-Einstellungen',
          'acceptAllBtn': 'Alle akzeptieren',
          'acceptNecessaryBtn': 'Alle ablehnen',
          'savePreferencesBtn': 'Einstellungen speichern',
          'closeIconLabel': 'Modal schließen',
          'serviceCounterLabel': 'Service|Dienste',
          'sections': [
            {
              'title': 'Wir verwenden Cookies.',
              'description': 'Hier können Sie einsehen und anpassen, welche Information wir über Sie speichern. Um mehr zu erfahren, lesen Sie bitte unsere <a href="/datenschutz">Datenschutzerklärung</a>.'
            },
            {
              'title': 'Technisch notwendige Cookies <span class="pm__badge">Immer aktiviert</span>',
              'description': 'Notwendige Cookies und Dienste ermöglichen grundlegende Funktionen und sind für die bestmögliche Funktion der Website erforderlich.',
              'linkedCategory': 'necessary'
            },
            {
              'title': 'Embed Content',
              'description': 'Auf unserer Website binden wir Inhalte von Drittanbietern zur Medienwiedergabe ein. Werden die Inhalte angezeigt, werden personenbezogene Daten an den jeweiligen Anbieter übertragen, ggf. werden auch weitere Cookies gesetzt.',
              'linkedCategory': 'embed',
              'cookieTable': {
                'headers': {
                  'name': 'Cookie',
                  'Service': 'Service',
                  'description': 'Beschreibung'
                }
              }
            }
          ]
        }
      },
    },
  },
})

window.showCookieSettings = function () {
  CookieConsent.showPreferences()
}
